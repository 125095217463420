<script>
export default {
  name: 'AuthComponent',
  props : {
    adminlevel : Number
  },
  data : function(){
    return{
      auth : {}
    }
  },
  mounted : function(){
    this.getSavedAuth();
  },
  watch:{
    $route (to){
      if(to.path == "/login") this.$emit("result", {})
      else this.getSavedAuth();
    }
  },
  methods:{
    verifyAuth(){
      fetch("https://app.danstechservices.us:5900/api/authenticate", { method: "POST", headers: { auth : JSON.stringify( this.auth ) } })
        .then(res => res.json())
        .then(res => {
          if(res.results != "Failed"){
            this.auth = res.results[0];
            this.saveVerifiedAuth();
            if(this.adminlevel != undefined && this.auth.isadmin < this.adminlevel) window.location.href = "/"; 
            this.$emit('result', this.auth);
          }
          else if(!window.location.hash.toLowerCase().includes('login')) window.location.href = "#/login"
        })
    },
    saveVerifiedAuth(){
      localStorage.setItem("auth", JSON.stringify( this.auth ));
    },
    getSavedAuth(){
      this.auth = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};

      if(!this.auth.id && !window.location.hash.toLowerCase().includes('login')) {
        window.location.href = "#/login"
      }
      else this.verifyAuth();
    }
  }
}
</script>