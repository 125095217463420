<template>
  <AuthComponent @result="handleAuth" />

  <v-app-bar :elevation="2">
    <v-app-bar-title>Dashboard</v-app-bar-title>
    <v-spacer />
    <v-btn variant="tonal" text="refresh View" @click="refreshView"/>
  </v-app-bar>

  <v-row class="hidden-lg-and-up">
    <v-col cols="12">
      <v-alert v-if="!loadingversion && minserverversion > appversion" type="error" text="Refresh your browser to update" />
      <v-alert v-else-if="!loadingversion && serverversion > appversion" type="warning" text="Refresh your browser to update" />
    </v-col>
  </v-row>

  <v-container fluid>
    <v-row align="center" justify="center" dense>
      <v-col cols="4">
        <v-card variant="outlined" link color="primary" :title="devices.length" subtitle="Devices" to="/devices" />
      </v-col>
      <v-col cols="4">
        <v-card variant="outlined" link color="primary" :title="activetickets.length" subtitle="Open Tickets" to="/tickets" />
      </v-col>
      <v-col cols="4">
        <v-card variant="outlined" link color="primary" :title="activealerts.length" subtitle="Active Alerts" to="/alerts" />
      </v-col>
    </v-row>
    <v-row align = "center" justify="center" dense v-if="auth.isadmin > 0" class="hidden-lg-and-up">
      <v-col cols="12">
        <v-card variant="outlined" link color="primary" title="Service Calls" to="/service-call" />
      </v-col>
    </v-row>
    <v-row align = "center" justify="center" dense v-if="auth.isadmin > 1" class="hidden-lg-and-up">
      <v-col cols="12">
        <v-card variant="outlined" link color="primary" title="Admin" to="/admin" />
      </v-col>
    </v-row>
    <v-row align = "center" justify="center" style="height: calc(100vh - 300px);" dense>
      <v-col cols="12" md = "4" class="fill-height">
        <v-card class="fill-height" style="position: relative;" variant="outlined" color="primary" title="Devices">
          <v-skeleton-loader v-if="loadingdevices" type="list-item-two-line"></v-skeleton-loader>
          <v-card v-if="devices.length == 0 && !loadingdevices" variant="flat" title="Nothing but the birds!" append-icon="mdi-bird" />

          <v-container style="position: absolute; bottom: 5px; left: 5px; height: calc(100% - 48px); width: calc(100% - 10px);">
            <v-row v-if="devices.length > 0">
              <v-col cols="6">Device Name</v-col>
              <v-col>Online</v-col>
              <v-col>Status</v-col>
            </v-row>
            <v-virtual-scroll :items="devices" style="position: absolute; bottom: 5px; height: calc(100% - 64px); width: 95%; overflow-y: auto; overflow-x: hidden; scrollbar-width: thin;">
              <template v-slot:default="{ item }">
                <v-row>
                  <v-col cols="6"><v-btn link color="primary" variant="outlined" to="/devices">{{ item.devicename }}</v-btn></v-col>
                  <v-col>
                    <v-chip v-if="item.online == undefined || item.online == -1" variant="outlined" >N/A</v-chip>
                    <v-chip v-if="item.online == 0" variant="outlined" color="red">Offline</v-chip>
                    <v-chip v-if="item.online == 1" variant="outlined" color="green">Online</v-chip>
                  </v-col>
                  <v-col>
                    <v-chip v-if="item.status == undefined" variant="outlined">N/A</v-chip>
                    <v-chip v-if="item.status == 0" variant="outlined" color="green">Good</v-chip>
                    <v-chip v-if="item.status == 1" variant="outlined" color="orange">Caution</v-chip>
                    <v-chip v-if="item.status == 2" variant="outlined" color="red">Warning</v-chip>
                  </v-col>
                </v-row>
              </template>
            </v-virtual-scroll>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md = "4" class="fill-height">
        <v-card height="100%" variant="outlined" color="primary" title="Recent Tickets">
          <v-skeleton-loader v-if="loadingtickets" type="list-item-two-line"></v-skeleton-loader>
          <v-card v-if="recenttickets.length == 0 && !loadingtickets" variant="flat" title="Nothing but the birds!" append-icon="mdi-bird" />
          <v-virtual-scroll :items="recenttickets" style="position: absolute; bottom: 5px; left: 20px; height: calc(100% - 64px); width: 95%; overflow-y: auto; overflow-x: hidden; scrollbar-width: thin;">
            <template v-slot:default="{ item }">
              <TicketComponent :ticket="item" :auth="auth" :view="0" :search="''" />
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
      <v-col cols="12" md = "4" class="fill-height">
        <v-card class="fill-height" variant="outlined" color="primary" title="Recent Alerts">
          <v-virtual-scroll :items="recentalerts" style="position: absolute; bottom: 5px; left: 20px; height: calc(100% - 64px); width: 95%; overflow-y: auto; overflow-x: hidden; scrollbar-width: thin;">
            <template v-slot:default="{ item }">
              <AlertComponent :alert="item" />
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthComponent from '@/components/AuthComponent.vue';
import TicketComponent from '@/components/TicketComponent.vue';
import AlertComponent from '@/components/AlertComponent.vue';

export default {
  name: 'DashboardView',
  components:{
    AuthComponent,
    TicketComponent,
    AlertComponent
  },
  data : function(){
    return{
      auth : {},
      activetickets : [],
      activealerts : [],
      recentalerts : [],
      warrantydevices : [],
      eoldevices : [],
      servicedevices : [],
      devices : [],
      recenttickets : [],

      loadingtickets : true,
      loadingalerts : true,
      loadingdevices : true,
      loadingversion : false,
      serverversion : null,
      minserverversion : null
    }
  },
  created : function(){
    this.getAppVersion();

    setInterval(()=>{
      this.getAppVersion();
    }, 60000)
  },
  methods:{
    handleAuth(value){
      this.auth = value;
      this.refreshView();
    },
    refreshView(){
      this.getDevices();
      this.getRecentTickets();
    },
    getDevices(){
      this.loadingdevices = true;
      this.devices = [];

      fetch("https://app.danstechservices.us:5900/api/getdevices", { method: "POST", headers: { auth : JSON.stringify( this.auth ) }})
        .then(res => res.json())
        .then(res => {
          this.devices = res.results;
          this.loadingdevices = false;
        })
    },
    getAppVersion(){
      this.loadingversion = true;
      fetch("https://app.danstechservices.us:5900/api/getversion", { method: "POST" })
        .then(res => res.json())
        .then(res => {
          this.serverversion = res.results.serverversion;
          this.minserverversion = res.results.minserverversion;
          this.loadingversion = false;
        })
    },
    getRecentTickets(){
      this.loadingtickets = true;
      this.recenttickets = [];

      fetch("https://app.danstechservices.us:5900/api/getrecenttickets", { method: "POST", headers: { auth : JSON.stringify( this.auth ) }})
        .then(res => res.json())
        .then(res => {
          if(res.results != 'Failed'){
            this.recenttickets = res.results;
            this.loadingtickets = false;
          }
        });
      fetch("https://app.danstechservices.us:5900/api/getopentickets", { method: "POST", headers: { auth : JSON.stringify( this.auth ) }})
        .then(res => res.json())
        .then(res => {
          if(res.results != 'Failed'){
            this.activetickets = res.results;
          }
        });
    }
  }
}
</script>