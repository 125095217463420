<template>
  <v-row v-if=" (view == 0 || (view == 1 && alert.ignored == 0) || (view == 2 && alert.ignored == 1)) && alert.message.toLowerCase().includes(search.trim().toLowerCase())">
    <v-col cols="12">
      <v-card :title="alert.message" :subtitle="(alert.devicename ? 'Device: ' + alert.devicename + ' - Created on ' : 'Created on ') + new Date(alert.timestamp).toLocaleDateString()" :color="alert.ignored == 1 ? 'grey-lighten-3' : alert.alerttype == 1 ? 'yellow' : 'red'">
        <v-card-actions v-if="alert.ignored == 0">
          <v-btn variant="tonal" text="Ignore" @click="ignoreAlert"/>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AlertComponent',
  props : {
    auth : Object,
    alert : Object,
    view : Number,
    search : String
  },
  methods:{
    ignoreAlert(){
      fetch("https://app.danstechservices.us:5900/api/ignorealert", { method: "POST", headers: { auth : JSON.stringify(this.auth), alert : JSON.stringify(this.alert) }})
        .then(res => res.json())
        .then(res => {
          if(res.results != 'Failed'){
            this.$emit('success');
          }
        })
    }
  }
}
</script>