<template>
  <AuthComponent @result="handleAuth" />

  <v-navigation-drawer>
    <v-list-item title="Dan's Tech Services" subtitle="Service App"></v-list-item>
    <v-divider />
    <v-alert v-if="!loadingversion && minserverversion > appversion" type="error" text="Refresh your browser to update" />
    <v-alert v-else-if="!loadingversion && serverversion > appversion" type="warning" text="Refresh your browser to update" />
    <v-divider></v-divider>
    <v-list-item v-if="auth.id" link title="Dashboard" to="/"></v-list-item>
    <v-list-item v-if="auth.id" link title="Devices" to="/devices"></v-list-item>
    <v-list-item v-if="auth.id" link title="Tickets" to="/tickets"></v-list-item>
    <v-list-item v-if="auth.id" link title="Service Logs" to="/service-logs"></v-list-item>
    <!-- <v-list-item v-if="auth.id" link title="Alerts" to="/alerts"></v-list-item> -->
    <v-list-item v-if="auth.id" link title="Account" to="/account"></v-list-item>
    <v-list-item v-if="auth.isadmin > 0" link title="Invoice Batching" to="/invoice-batching"></v-list-item>
    <v-list-item v-if="auth.isadmin > 0" link title="Service Call" to="/service-call"></v-list-item>
    <v-list-item v-if="auth.isadmin > 1" link title="Admin" to="/admin"></v-list-item>
    <v-list-item link :title="auth.id ? 'Logout' : 'Login'" to="login"></v-list-item>
  </v-navigation-drawer>
</template>

<script>
import AuthComponent from '@/components/AuthComponent.vue';

export default {
  name: 'SideBar',
  components:{
    AuthComponent
  },
  props : {
    version : Number
  },
  data : function(){
    return{
      auth : {},
      loadingversion : false,
      serverversion : null,
      minserverversion : null
    }
  },
  created : function(){
    this.getAppVersion();

    setInterval(() => {
      this.getAppVersion();
    }, 60000);
  },
  methods:{
    handleAuth(value){
      this.auth = value;
    },
    getAppVersion(){
      this.loadingversion = true;
      fetch("https://app.danstechservices.us:5900/api/getversion", { method: "POST" })
        .then(res => res.json())
        .then(res => {
          this.serverversion = res.results.serverversion;
          this.minserverversion = res.results.minserverversion;
          this.loadingversion = false;
        })
    }
  }
}
</script>

<style scoped>

</style>