<template>
  <v-dialog width="900" v-if="(button || (view == 0 || (view == 1 && ticket.status == 2) || (view == 2 && ticket.status == 1)) && (ticket.message.toLowerCase().includes(search.toLowerCase()) || ticket.clientname && ticket.clientname.toLowerCase().includes(search.toLowerCase())))">
    <template v-slot:activator="{ props }">
      <v-row v-if="!button">
        <v-col cols="12">
          <v-card :color="ticket.status == 2 ? 'yellow' :'green'" v-bind="props" @click="getTicketInfo()" :title="'Ticket #' + ticket.id + ': ' + ticket.message" :subtitle="'Created on ' + (ticket.datecreated ? new Date(ticket.datecreated).toISOString().split('T')[0] : 'N/A') + (datecompleted ? ' | Completed on ' + datecompleted : '')">
            <v-card-text>{{ ticket.notes }}</v-card-text>
            <v-card-text v-if="ticket.clientname">{{ ticket.clientname }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-btn v-else variant="tonal" v-bind="props" @click="getTicketInfo()" text="View Details" />
    </template>

    <template v-slot:default="{ isActive }">
      <v-card :title="'Ticket #' + ticket.id + ': ' + ticket.message">
        <template v-slot:prepend>
          <v-icon :icon="ticket.status == 1 ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline'" :color="ticket.status == 1 ? 'green' : 'orange'"></v-icon>
        </template>

        <v-card-text>{{ ticket.notes }}</v-card-text>

        <v-row>
          <v-skeleton-loader v-if="loadingdevice" type="list-item"></v-skeleton-loader>
          <v-col v-if="device && device.id != null">
            <v-card-text>Device: {{ device.devicename }}</v-card-text>
          </v-col>
          <v-col>
            <v-skeleton-loader v-if="loadingevents" type="list-item"></v-skeleton-loader>
            <v-card-text>Assigned Tech: {{ assignedtech ? assignedtech.name : 'Unassigned' }}</v-card-text>
          </v-col>
          <v-col>
            <v-skeleton-loader v-if="loadingevents" type="list-item"></v-skeleton-loader>
            <v-card-text v-if="ticket.status == 1">Completed In: {{ ticketduration + ' minute' + (ticketduration > 1 || ticketduration == 0 ? 's' : '') }}</v-card-text>
            <v-card-text v-if="ticket.status == 1 && assignedtech && assignedtech.rate > 0">Ticket Cost: {{ "$ " + ((ticketduration/60) * assignedtech.rate - discount + additionalcost).toFixed(2) }}</v-card-text>
          </v-col>
        </v-row>
        
        <v-skeleton-loader v-if="loadingevents" type="list-item-two-line"></v-skeleton-loader>
        <v-timeline side="end" truncate-line="both">
          <v-timeline-item v-for="item in events" dot-color="green" :key="item" size="small">
            <v-card prepend-icon="mdi-information-outline" variant="tonal" :color="item.event_type == 5 ? 'orange' : ''" :title="item.contactname" :subtitle="new Date(item.timestamp).toLocaleDateString() + ' at ' + new Date(item.timestamp).toLocaleTimeString()">
              <v-card-text v-if="item.event_type == 1">Work Started</v-card-text>
              <v-card-text v-if="item.event_type == 2">{{ 'Work Stopped ' + (item.duration ? '- Time Elapsed: ' + item.duration + ' minute' + (item.duration > 1 || item.duration == 0 ? 's' : '') : '') }}</v-card-text>
              <v-card-text v-if="item.event_type == 3">{{ 'Marked Completed - Total Time Elapsed: ' + ticketduration + ' minute' + (ticketduration > 1 || ticketduration == 0 ? 's' : '') }}</v-card-text>
              <v-card-text v-if="item.event_type == 4">Tech Assigned</v-card-text>
              <v-card-text v-if="item.event_type == 5">Customer Note: {{ item.notes }}</v-card-text>
              <v-card-text v-else-if="item.notes && item.notes.trim().length > 0 && item.event_type==7">Discount Applied: ${{ parseFloat(item.notes).toFixed(2) }}</v-card-text>
              <v-card-text v-else-if="item.notes && item.notes.trim().length > 0 && item.event_type==8">Additional Cost Applied: ${{ parseFloat(item.notes).toFixed(2) }}</v-card-text>
              <v-card-text v-else-if="item.notes && item.notes.trim().length > 0">Tech Notes: {{ item.notes }}</v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        
        <v-container v-if="ticket.status == 2">
          <v-textarea placeholder="Add a note with your action" v-model="note"/>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="ticket.status == 2" :prepend-icon="ticket.watched == 0 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :text=" ticket.watched == 0 ? 'Watch Ticket' : 'Stop Watching Ticket'" @click="updateWatch(isActive)" />
          <v-btn v-if="ticket.status == 2" text="Add Note" @click="submitNote(isActive)" />
          <v-btn text="Close" @click="isActive.value = false;" />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'TicketComponent',
  props : {
    auth : Object,
    ticket : Object,
    search : String,
    view : Number,
    button : Boolean
  },
  data : function(){
    return{
      loadingevents : true,
      loadingdevice : true,
      events : [],
      device : null,
      ticketduration : 0,
      assignedtech : null,
      note : "",
      lastpunch : 0,
      datecompleted : null,
      discount : 0,
      additionalcost : 0
    }
  },
  mounted : function(){
    this.getEvents();
  },
  methods:{
    getTicketInfo(){
      this.note = "";
      this.getEvents();
      this.getDevice();
    },
    getEvents(){
      this.loadingevents = true;
      this.events = [];

      fetch("https://app.danstechservices.us:5900/api/getticketevents", { method: "POST", headers: { auth : JSON.stringify( this.auth ), ticket : JSON.stringify( this.ticket ) }})
        .then(res => res.json())
        .then(res => {
          this.ticketduration = 0;
          this.datecompleted = null;
          this.discount = 0;
          this.additionalcost = 0;

          for(let i = 0; i < res.results.length; i++){
            if(res.results[i].event_type == 1){
              this.lastpunch = res.results[i];
            }
            if(res.results[i].event_type == 2){
              let datediff = Math.abs(new Date(res.results[i].timestamp) - new Date(this.lastpunch.timestamp));
              res.results[i].duration = Math.ceil(datediff / 60000);
              this.ticketduration += Math.ceil(datediff / 60000);
              this.lastpunch = res.results[i];
            }
            if(res.results[i].event_type == 3){
              this.datecompleted = new Date(res.results[i].timestamp).toISOString().split('T')[0];
            }
            if(res.results[i].event_type == 4){
              this.assignedtech = { name : res.results[i].contactname, rate : res.results[i].hourlyrate };
            }
            if(res.results[i].event_type == 7){
              this.discount += parseFloat(res.results[i].notes)
            }
            if(res.results[i].event_type == 8){
              this.additionalcost += parseFloat(res.results[i].notes)
            }
          }

          this.events = res.results;
          this.loadingevents = false;
        })
    },
    getDevice(){
      fetch("https://app.danstechservices.us:5900/api/getticketdevice", { method: "POST", headers: { auth : JSON.stringify( this.auth ), ticket : JSON.stringify( this.ticket ) }})
        .then(res => res.json())
        .then(res => {
          this.device = res.results[0];
          this.loadingdevice = false;
        })
    },
    updateWatch(isActive){
      fetch("https://app.danstechservices.us:5900/api/updateticketwatch", { method: "POST", headers: { auth : JSON.stringify( this.auth ), ticket : JSON.stringify( this.ticket ), setting : this.ticket.watched == 0 ? 1 : 0 }})
        .then(res => res.json())
        .then(res => {
          if(res.results == 'Success'){
            this.getTicketInfo();
            isActive.value = false;
            this.$emit("success");
        }})
    },
    submitNote(isActive){
      fetch("https://app.danstechservices.us:5900/api/addticketevent", { method: "POST", headers: { auth : JSON.stringify( this.auth ), ticket : JSON.stringify( this.ticket ), event : JSON.stringify( { notes : this.note, type : 5 } ) }})
        .then(res => res.json())
        .then(res => {
          if(res.results == 'Success'){
            this.getTicketInfo();
            isActive.value = false;
        }})
    }
  }
}
</script>