import { createRouter, createWebHashHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/devices',
    name: 'devices',
    component: () => import('../views/DevicesView.vue')
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('../views/TicketsView.vue')
  },
  {
    path: '/service-logs',
    name: 'service logs',
    component: () => import('../views/ServiceLogView.vue')
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () => import('../views/AlertsView.vue')
  },
  {
    path: '/invoice-batching',
    name: 'invoice batching',
    component: () => import('../views/InvoiceBatchingView.vue')
  },
  {
    path: '/service-call',
    name: 'service call',
    component: () => import('../views/ServiceCallView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/AdminView.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/AccountView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
